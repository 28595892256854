import React from 'react';
import { Jumbotron, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import threading from '../images/services/threading.jpeg';
import waxing from '../images/services/waxing.jpeg';
import bleach from '../images/services/bleach.jpeg';
import hairMassage from '../images/services/hairMassage.jpeg';

const cardStyle = {
  objectFit: 'cover',
  width: '120%',
  height: '40%',
};
const Services = () => {
  return (
    <Jumbotron className="jumboAbout d-flex min-vh-100" fluid>
      <Container>
        <Row md={4} className="margin-left: 10rem justify-content-center">
          <Col className="col-lg-3">
            <Card className="shadow-1-strong align-items-center">
              <Card.Img
                src={threading}
                className="img-responsive"
                variant="top"
                fluid={true}
                style={cardStyle}
              />
              <Card.Body>
                <Card.Title>
                  <h1 className="card-Title ">Threading</h1>
                </Card.Title>
                <hr className="new5" />
                <Card.Text>
                  <ul className="list-inline mx-auto justify-content-center">
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Eyebrows
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Upper-Lip
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Chin
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Full-Chin
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Cheeks
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Neck
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-lg-3">
            <Card className=" shadow-1-strong align-items-center">
              <Card.Img
                src={waxing}
                className="img-responsive"
                variant="top"
                fluid={true}
                style={cardStyle}
              />
              <Card.Body>
                <Card.Title>
                  <h1 className="card-Title ">Waxing</h1>
                </Card.Title>
                <hr className="new5" />
                <Card.Text>
                  <ul className="list-inline mx-auto justify-content-center">
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Under Arms
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Full-Face
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Full-Arms
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Full-Legs
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Bikini-Wax
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Brazillian-Wax
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-lg-3">
            <Card className="shadow-1-strong align-items-center">
              <Card.Img
                src={bleach}
                className="img-responsive"
                variant="top"
                fluid={true}
                style={cardStyle}
              />
              <Card.Body>
                <Card.Title>
                  <h1 className="card-Title ">Facial and Bleach</h1>
                </Card.Title>
                <hr className="new5" />
                <Card.Text>
                  <ul className="list-inline mx-auto justify-content-center">
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Bleach
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Facial
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-lg-3">
            <Card className="shadow-1-strong align-items-center ">
              <Card.Img
                src={hairMassage}
                className="img-responsive"
                variant="top"
                fluid={true}
                style={cardStyle}
              />
              <Card.Body>
                <Card.Title>
                  {' '}
                  <h1 className="card-Title ">Hair Massage</h1>
                </Card.Title>
                <hr className="new5" />
                <Card.Text>
                  <ul className="list-inline mx-auto justify-content-center">
                    <li>
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faCheck}
                      />
                      Hair Massage
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
};

export default Services;
