import React, { Component } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';
import Jumbotron from 'react-bootstrap/Jumbotron';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Container } from 'react-bootstrap';
import Footer from './Footer';

let markers = [
  {
    id: 1,
    latitude: 37.9504,
    longitude: -122.33094,
    shelter: '13112 San Pablo Ave, Richmond, CA - 94805',
  },
  {
    id: 2,
    latitude: 38.014986273285345,
    longitude: -122.26733575738749,
    shelter: '1511 Sycamore Ave #h Hercules, CA 94547',
  },
];
const today = new Date();
const year = today.getFullYear();
const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBe1oMgVj7_OaN2eFZpA9E51HFzGve_N6g&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div class="map-canvas" />,
    mapElement: <div className="map-responsive " style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={11}
    defaultCenter={{ lat: 38.014986273285345, lng: -122.26733575738749 }}
  >
    {markers.map((marker) => {
      //const onClick = props.onClick.bind(this, marker)
      return (
        <Marker
          key={marker.id}
          position={{ lat: marker.latitude, lng: marker.longitude }}
        >
          <InfoWindow>
            <div
              style={{
                color: 'black',
                fontFamily: 'TImes, serif',
              }}
            >
              {marker.shelter}
            </div>
          </InfoWindow>
        </Marker>
      );
    })}
  </GoogleMap>
));

class Contact extends Component {
  render() {
    return (
      <Jumbotron className="jumboAbout d-flex min-vh-100" fluid>
        <Container>
          <p className="contactBookingText">
            📆 Book Your Appointment Today:{' '}
            <i class="fa fa-mobile" aria-hidden="true"></i> (510)815-8963 |
            (484)744-1930
          </p>
          <p className="contactLocationHeading">
            <i class="fa fa-map-marker" aria-hidden="true"></i> Our Locations
            and Hours
            <div class="parent">
              <div class="child">
                <p className="contactLocationText">
                  <i class="fa fa-map-pin"></i> 13112 San Pablo Ave, Richmond,
                  CA - 94805
                  <p className="contactHours">
                    Monday - Saturday : 10:00 A.M to 7:00 P.M <br></br>
                    Sunday : 11:00 A.M to 4:00 P.M
                  </p>
                </p>
              </div>
              <div class="child">
                <p className="contactLocationText">
                  <i class="fa fa-map-pin"></i> 1511 Sycamore Ave #h Hercules,
                  CA 94547
                  <p className="contactHours">
                    Monday - Thursday : 11:00 A.M to 6:30 P.M <br></br>
                    Friday - Saturday : 10:00 A.M to 6:30 P.M <br></br>
                    Sunday : Only By Appointment
                  </p>
                </p>
              </div>
            </div>
          </p>
          <MapWithAMarkerClusterer />
        </Container>
      </Jumbotron>
    );
  }
}
export default Contact;
