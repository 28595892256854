import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import About from './components/About';
import Services from './components/Services';

import Navigation from './components/Navigation';
import Contact from './components/Contact';

class App extends React.Component {
  render() {
    return (
      <div>
        <Navigation />

        <Routes>
          <Route path="/" element={<HomePage />} exact />
          <Route path="/about" element={<About />} exact />
          <Route path="/services" element={<Services />} exact />
          <Route path="/contact" element={<Contact />} exact />
        </Routes>
      </div>
    );
  }
}

export default App;
