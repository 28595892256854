import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import './../App.css';
import Container from 'react-bootstrap/Container';
import Footer from './Footer';

function HomePage() {
  return (
    <div>
      <div class="jumboHome"></div>
      <Footer />
    </div>
  );
}

export default HomePage;
