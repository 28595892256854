// Filename - components/Footer.js

import React from 'react';
import {
  Box,
  FooterContainer,
  Row,
  Column,
  FooterLink,
  Heading,
} from './FooterStyle';

const Footer = () => {
  return (
    <Box>
      {/* <FooterContainer> */}
      <p className="socialText">
        Find Us at :
        <a href="https://www.instagram.com/kseyebrows/?hl=en">
          {' '}
          <i class="fa fa-instagram fa-1x" style={{ color: 'brown' }}></i>
        </a>{' '}
        |
        <a href="https://www.yelp.com/biz/ks-eyebrows-richmond?osq=ks+eyebrows">
          {' '}
          <i
            class="fa fa-yelp fa-1x"
            aria-hidden="true"
            style={{ color: 'brown' }}
          ></i>
        </a>
      </p>
      {/* </FooterContainer> */}
    </Box>
  );
};
export default Footer;
