import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../App.css';

const About = () => {
  return (
    <Jumbotron className="jumboAbout d-flex fluid">
      <Container>
        <h1
          className="h1-responsive"
          style={Object.assign(
            { color: 'Brown' },
            { marginTop: 40 },
            { fontSize: 30 },
            { fontFamily: 'Ariel' },
            { textAlign: 'center' }
          )}
        >
          ~: About Us :~
        </h1>
        {/* <p className="lead"></p> */}
        <hr className="my-2" />
        {/* <p className="p4">
         
        </p> */}
        <p className="aboutSubheading">
          🌼 KS Eyebrows - Where Expertise Meets Beauty 🌼
        </p>
        <p className="pText">
          Don't settle for anything less than the best when it comes to your
          brows. Experience the artistry and precision of our expert staff today
          and reveal the true potential of your eyebrows!
        </p>
        <p className="pText">
          Located at Richmond and Herculus (Recently Opened). Our mission at KS
          Eyebrows is to provide a friendly, personalized service through a team
          of highly skilled and creative professionals. From Eyebrows threading
          and Waxing to Facial and bleach, you can choose from these services
          offered by KS Eyebrows.
        </p>
        {/* <p className="pText">
          💎 Experience Beauty Excellence with Our Expert Staff! 💎
        </p> */}
        <p className="pText">
          📖 <strong>Staff Expertise:</strong> At KS Eyebrows, we take pride in
          the exceptional talent and expertise of our staff. Our team of
          dedicated professionals is here to provide you with top-notch beauty
          services, ensuring that you look and feel your absolute best.
        </p>

        <p className="pText">
          <strong>📆 Book Your Appointment Today: </strong>
          Elevate your beauty game and embrace the expertise of our staff at KS
          Eyebrows. Book your appointment now and entrust your brows to the true
          professionals. Contact us at :{' '}
          <i class="fa fa-mobile" aria-hidden="true"></i> (510)815-8963 |
          (484)744-1930
        </p>
        <p className="socialText">
          Find Us at :
          <a href="https://www.instagram.com/kseyebrows/?hl=en">
            {' '}
            <i class="fa fa-instagram fa-1x" style={{ color: 'brown' }}></i>
          </a>{' '}
          |
          <a href="https://www.yelp.com/biz/ks-eyebrows-richmond?osq=ks+eyebrows">
            {' '}
            <i
              class="fa fa-yelp fa-1x"
              aria-hidden="true"
              style={{ color: 'brown' }}
            ></i>
          </a>
        </p>
      </Container>
    </Jumbotron>
  );
};

export default About;
